@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
}

.login-bg {
  background: url("assets/login-bg.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.user-child {
  visibility: hidden;
  opacity: 0;
}

.user-parent:hover .user-child {
  visibility: visible;
  opacity: 1;
}

select {
  -webkit-appearance: none;
  appearance: none;
}

.select-wrapper {
  position: relative;
}

.select-wrapper::after {
  content: "▼";
  font-size: 0.75rem;
  top: 50%;
  transform: translateY(-50%);
  right: 1%;
  position: absolute;
}

.image-child {
  opacity: 0;
  visibility: hidden;
}

.image-parent:hover .image-child {
  opacity: 1;
  visibility: visible;
}

.swiper-pagination-bullet-active {
  background-color: white !important;
}

.swiper-button-prev,
.swiper-button-next {
  color: grey !important;
  padding: 2rem;
  border-radius: 50%;
}

.product-child {
  opacity: 0;
  visibility: hidden;
}

.product-parent:hover .product-box {
  background-color: transparent;
}

.product-parent:hover .product-child {
  opacity: 1;
  visibility: visible;
}

.nav-child {
  width: 100%;
  max-width: 0;
  height: 1px;
  background-color: white;
}

.nav-parent {
  position: relative;
}

.nav-parent:hover .nav-child {
  max-width: 100%;
}
